import { useCallback, useEffect } from "react"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom"
import { useBirState } from "../Context/BirContextProvider"
import Layout from "../core/components/Layout"
import Logout from "../core/components/Logout"
import { NotFound } from "../core/components/NotFound"
import { ErrorBoundary } from "../core/interceptors/ErrorBoundary"
import { IRutasPrivadas } from "../core/types/common-types"
import PrivateRoute from "./PrivateRoute"
import { RutasPrivadas } from "./RutasPrivadas"

import { useGeneralEventDispatcher } from "../eventDispatcher/event-dispatcher-context"

import { TOAST_STATUS, ToastContextProvider } from "bionline-components-library"
import { ApiLanguage, Helpers, Languages } from "bionline-library-utilities"

import { BibotModule, LoginHome } from "bionline-modules"
import { UIScreen } from "../core/components/UIScreen"
import {
  CoreEvents,
  MensajeGenerico,
  Navigate as NavigateEvt
} from "../eventDispatcher/eventos/core_events"
import { CORE_EVENTS } from "../eventDispatcher/eventos/types"
import { AnimatePresence } from "framer-motion"
import { UserLogin } from "../core/types/user-login"

const signalR = require("@microsoft/signalr")

type ToastDispatcher<T> = keyof T
interface LoadIngestaDatosPayload {
  idCustomer: string
  retCode: number
}

const { LANGUAGES } = Languages

const AppRouter = () => {
  const generalEventDispatcher = useGeneralEventDispatcher()

  const { user, showHelp, setUser } = useBirState()
  const lang = Helpers.getApiLanguage(
    (user?.idioma as ApiLanguage) ?? LANGUAGES.SPANISH
  )

  const location = useLocation()
  const navigate = useNavigate()

  const callbackNavigate = useCallback(
    (evt: NavigateEvt<typeof CORE_EVENTS.NAVIGATE>) => {
      navigate(evt.url)
    },
    [navigate]
  )

  const callbackMensajeGenerico = useCallback(
    (evt: MensajeGenerico<typeof CORE_EVENTS.MENSAJE_GENERICO>) => {},
    []
  )

  const url = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    const urlHub = `${url}/api/Hub`
    let connection = new signalR.HubConnectionBuilder().withUrl(urlHub).build()

    connection.on(
      "loadedFilesIngestaDatos",
      (data: string) => {

      
        const [ idCustomer, resto] = data.split("-")
        const [,retCode]=resto.split("retCode:")
        if (user?.idCustomer.toLowerCase() === idCustomer.toLowerCase()) {
          if (Number(retCode) === 200) {
            generalEventDispatcher?.dispatch(CORE_EVENTS.MENSAJE_GENERICO, {
              type: CORE_EVENTS.MENSAJE_GENERICO,
              toast: {
                title: "Éxito",
                description:
                  "Se han cargado correctamente los ficheros. Puede usar Bionline Ingest cuando lo desee",
                status: TOAST_STATUS.SUCCESS
              },
              timestamp: new Date(),
              lang
            })
          } else {
            generalEventDispatcher?.dispatch(CORE_EVENTS.MENSAJE_GENERICO, {
              type: CORE_EVENTS.MENSAJE_GENERICO,
              toast: {
                title: "Error",
                description:
                  "Algo salió mal en la carga de los ficheros de Bionline Ingest. Póngase en contacto con el administrador",
                status: TOAST_STATUS.DANGER
              },
              timestamp: new Date(),
              lang
            })
          }

          setUser((user) => {
            if (user) {
              return { ...user, inRellenarIngestaDatos: 1 }
            } else {
              return user
            }
          })
        }
      }
    )

    connection.start().then(() => console.log("Conectado"))

    return () => {
      if (connection) {
        connection.stop()
      }
    }
  }, [setUser, url, user?.idCustomer])

  useEffect(() => {
    generalEventDispatcher.subscribe(CORE_EVENTS.NAVIGATE, callbackNavigate)
    generalEventDispatcher.subscribe(
      CORE_EVENTS.MENSAJE_GENERICO,
      callbackMensajeGenerico
    )
    return () => {
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.NAVIGATE,
        callbackNavigate
      )
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.MENSAJE_GENERICO,
        callbackMensajeGenerico
      )
    }
  }, [callbackMensajeGenerico, callbackNavigate, generalEventDispatcher])

  const TOAST_EVENTS: ToastDispatcher<CoreEvents>[] = [
    CORE_EVENTS.MENSAJE_GENERICO
  ]

  return (
    <AnimatePresence>
      <UIScreen lang={lang} eventDispatcher={generalEventDispatcher}>
        <ToastContextProvider<CoreEvents>
          events={TOAST_EVENTS}
          eventDispatcher={generalEventDispatcher}
        >
          <Layout isLoggedIn={user !== undefined}>
            <ErrorBoundary>
              <Routes location={location} key={location.pathname}>
                <Route
                  path="/auth/signin"
                  element={
                    user === undefined ? (
                      <LoginHome
                        generalEventDispatcher={generalEventDispatcher}
                      />
                    ) : (user as UserLogin).inVisualizarIngestaDatos === 1 &&
                      (user as UserLogin).inCompletadaIngesta === 0 ? (
                      <Navigate to="/bloques" />
                    ) : (
                      <Navigate to="/audiences/accion-comercial/home" />
                    )
                  }
                />
                <Route path="/auth/logout" element={<Logout />} />
                <Route
                  element={
                    <PrivateRoute isAuthenticated={user !== undefined} />
                  }
                >
                  {RutasPrivadas.map(
                    ({ component: Component, path, id }: IRutasPrivadas) => {
                      return (
                        <Route
                          key={id}
                          path={path}
                          element={
                            <>
                              <Component
                                user={user}
                                generalEventDispatcher={generalEventDispatcher}
                              />
                              {/*showHelp && (
                                <BibotModule token={user?.jwToken} url={url} />
                              )*/}
                            </>
                          }
                        />
                      )
                    }
                  )}
                </Route>
                <Route path="/" element={<Navigate to="/auth/signin" />} />
                <Route
                  path="/NotFound"
                  element={<NotFound navigate={navigate} />}
                />
                <Route path="*" element={<NotFound navigate={navigate} />} />
              </Routes>
            </ErrorBoundary>
          </Layout>
        </ToastContextProvider>
      </UIScreen>
    </AnimatePresence>
  )
}

export default AppRouter
